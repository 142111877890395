// ReactComponent.jsx

import React from 'react';
import './App.css';
import logo from "./Images/logo-german.png"
const App = () => {
  const contactEmail = ': info@kaufetronicsgmbh.de';
    return (
        <section>
            <div className="container">
                <div className="content">
                    <div className="company">
                       <img src={logo} alt='' style={{width:"300px"}} />
                    </div>

                    <h1 className="title">Website Under Maintenance !</h1>

                    <div className="description">
                    <p>
                            We are currently performing scheduled maintenance. Sorry for the inconvenience. Please check back later.
                        </p>
                       
                    </div>

                    <div className="button">
                    <p>
                            For any queries, please contact us at <a href={`mailto:${contactEmail}`} style={{fontSize:"25px",color:"orange"}}>{contactEmail}</a>.
                        </p>
                    </div>

                    <div className="icons">
                        <button><ion-icon name="logo-facebook"></ion-icon></button>
                        <button><ion-icon name="logo-instagram"></ion-icon></button>
                        <button><ion-icon name="logo-twitter"></ion-icon></button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default App;
